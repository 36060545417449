<template>
  <div>
    <!--begin::Head-->
<!--    <div class="kt-login__head">-->
<!--      <span class="kt-login__signup-label">{{-->
<!--        $t("AUTH.GENERAL.NO_ACCOUNT")-->
<!--      }}</span-->
<!--      >&nbsp;&nbsp;-->
<!--      <router-link-->
<!--        class="kt-link kt-login__signup-link"-->
<!--        :to="{ name: 'register' }"-->
<!--      >-->
<!--        {{ $t("AUTH.GENERAL.SIGNUP_BUTTON") }}-->
<!--      </router-link>-->
<!--    </div>-->
    <!--end::Head-->

    <!--begin::Body-->
    <div class="kt-login__body">
      <!--begin::Signin-->
      <div class="kt-login__form">
        <div class="kt-login__title">
          <h3>Reset password</h3>
        </div>

        <!--begin::Form-->
        <b-form class="kt-form" @submit.stop.prevent="onSubmit">
          <div role="alert" class="alert alert-info">
            <div class="alert-text">
              Inserire la nuova password
            </div>
          </div>

          <div
            role="alert"
            v-bind:class="{ show: errors.length }"
            class="alert fade alert-danger"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ error }}
            </div>
          </div>

          <b-form-group
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
          >
            <b-form-input
                    type="password"
                    id="example-input-2"
                    name="example-input-2"
                    placeholder="Nuova password"
                    v-model="$v.form.password.$model"
                    :state="validateState('password')"
                    aria-describedby="input-2-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-2-live-feedback">
              Password richiesta
            </b-form-invalid-feedback>
          </b-form-group>

          <!--begin::Action-->
          <div class="kt-login__actions">
            <router-link :to="{ name: 'login', params: {  }}" class="kt-link kt-login__link-forgot">
              Vai al Login
            </router-link>
            <b-button
                    type="submit"
                    id="kt_submit"
                    class="btn btn-primary btn-elevate kt-login__btn-primary"
            >
              Conferma
            </b-button>
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->

      </div>
      <!--end::Signin-->
    </div>
    <!--end::Body-->
  </div>
</template>

<style lang="scss" scoped>
.kt-spinner.kt-spinner--right:before {
  right: 8px;
}
</style>

<script>
import { mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import ApiService from "@/common/api.service";
import Vue from "vue";

export default {
  mixins: [validationMixin],
  name: "reset",
  data() {
    return {
      // Remove this dummy login info
      form: {
        // email: "admin@demo.com",
        password: ""
      }
    };
  },
  validations: {
    form: {
      password: {
        required,
        // minLength: minLength(3)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$router.currentRoute.params.email;
      const token = this.$route.query.token;
      const password = this.$v.form.password.$model;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = document.getElementById("kt_submit");
      submitButton.classList.add(
        "kt-spinner",
        "kt-spinner--light",
        "kt-spinner--right"
      );

      // dummy delay
      setTimeout(() => {
        // send login request
        ApiService.post("auth/reset-password", { email: email, token:token, new_password:password })
                .then(({ data }) => {
                  Vue.customNotifySuccess('Reset confermato','Accedi con la nuova password');
                  setTimeout(() => {
                    this.$router.push({ name: "login" })
                  },4000);
                })
                .catch(({ response }) => {
                  Vue.customNotifyError('Errore',response.data&&response.data.errors?response.data.errors.join(', '):'Si prega di riprovare');
                });

        submitButton.classList.remove(
          "kt-spinner",
          "kt-spinner--light",
          "kt-spinner--right"
        );
      }, 2000);
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage() {
      return window.whitelabel.getAppBackground();
    }
  }
};
</script>
